<template>
  <v-text-field :value="value"
              @input="onUpdateValue"
              outlined
              :disabled="isReadSurvey || options.readonly"
  />
</template>

<script>
import questionMixins from '@/components/Survey/Reader/questionMixins'

export default {
  name: 'TextInput',

  mixins: [questionMixins],
}
</script>
